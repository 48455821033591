/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser } from "@remix-run/react";
import { startTransition, StrictMode } from "react";
import { hydrate } from 'react-dom'
import { hydrateRoot } from 'react-dom/client';
import { Buffer } from "buffer";
import { useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import * as SentryBrowser from '@sentry/browser'
import { useEffect } from "react";
import { APPLICATION_VERSION, APPLICATION_ENV } from "./utils/appversion.client";

const shouldTrack = ({environment}:any) => { 
  if(environment != 'dev'){
    return true
  }

  return false;
}

Sentry.init({
  dsn: "https://67997904aa34e9f42534efe48b27cea9@o4506010189299712.ingest.sentry.io/4506079908134912",
  release: "oversite-web-app@" + APPLICATION_VERSION,
  environment: APPLICATION_ENV,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.browserProfilingIntegration(),
    SentryBrowser.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
      // See below for all available options
    })
  ],
  tracePropagationTargets: ["https://app.addoversite.com/", "https://collier.addoversite.com/", "https://lda.addoversite.com/", "https://ttl.addoversite.com/"],
  profilesSampleRate: shouldTrack(APPLICATION_ENV) ? 1.0 : 0,
  tracesSampleRate: shouldTrack(APPLICATION_ENV) ? 1.0 : 0,
  replaysSessionSampleRate: shouldTrack(APPLICATION_ENV) ? 0.1 : 0,
  replaysOnErrorSampleRate: shouldTrack(APPLICATION_ENV) ? 1.0 : 0,
});

globalThis.Buffer = Buffer;

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
    // {
    //   onRecoverableError(error, errorInfo) {
    //     console.error(
    //       'onRecoverableError HYDRATION error::',
    //       error,
    //       errorInfo.componentStack
    //     );
    //   },
    //   onUncaughtError: (error, errorInfo) => {
    //     console.error(
    //       'onUncaughtError HYDRATION error::',
    //       error,
    //       errorInfo.componentStack
    //     );
    //   }
    // }
  );
});
